import React from "react"

import {
  extractRegionData,
  extractIndustryData,
  extractPassport1Data,
  extractPassport2Data,
  extractSyndicateReport,
  extractUpcomingsData,
} from "../../services"

import ReportTable from "../Shared/ReportTable"
import InnerPageBanner from "../Shared/InnerPageBanner"
import HyperlinkList from "../Shared/HyperlinkList"

export default function({ slug }) {
  const regionData = extractRegionData()
  const industryData = extractIndustryData()
  const allData = [
    ...extractPassport1Data(),
    ...extractPassport2Data(),
    ...extractSyndicateReport(),
    ...extractUpcomingsData(),
  ]
  const industrySector = industryData
    .map(e => e.industrySectorList)
    .flat()
    .find(e => e.slug === slug)
  const data = allData.filter(e => e.ind_sect_id == industrySector.id)
  const dataWithDate = data
    .filter(e => e.month)
    .sort((a, b) => new Date(b.month) - new Date(a.month))
  const dataWithoutDate = data.filter(e => !e.month)
  return (
    <>
      <InnerPageBanner
        headingText={industrySector.sectorName}
        linkText={industrySector.sectorName}
      />
      <div className="container">
        <div className="row">
          <div className="col-md-3">
            <HyperlinkList
              headingText={"Industries"}
              linkList={industryData.map(e => {
                return {
                  name: e.name,
                  href: `/report/${e.slug}`,
                }
              })}
            />
            <HyperlinkList
              headingText={"Regions"}
              linkList={regionData.map(e => {
                return {
                  name: e.cat_name,
                  href: `/report/reg/${e.slug}`,
                }
              })}
            />
          </div>
          <div className="col-md-9">
            <ReportTable tableData={[...dataWithDate, ...dataWithoutDate]} />
          </div>
        </div>
      </div>
    </>
  )
}
